import React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import PrijsTabel from '../components/PrijsTabel'

export default class Prijzen extends React.Component {
  render() {
    console.log(this.props)
    const { data, path } = this.props
    const { title } = data.site.siteMetadata
    const metaDesc = 'Prijzen'

    return (
      <Layout path={path}>
        <Helmet>
          <title>{`Prijzen | ${title}`}</title>
          <meta name="description" content={metaDesc} />
          <meta name="og:description" content={metaDesc} />
        </Helmet>
        <section className="section">
          <PrijsTabel title="Prijzen" />
        </section>
      </Layout>
    )
  }
}

export const metaQuery = graphql`
  query Prijzen {
    site {
      siteMetadata {
        title
      }
    }
  }
`
