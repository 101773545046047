import React from 'react'
import { IconIncluded, IconExcluded } from './Icons'
import { Link } from 'gatsby'

const PrijsTabel = ({ title }) => {
  return (
    <div className="container">
      <h1 className="is-uppercase">{title}</h1>
      <div className="columns ">
        <div className="column is-4 is-flex">
          <div className="box has-background-white is-flex is-flex-direction-column">
            <div className="price-header">
              <div className="has-text-grey-dark is-size-4 font-medium mb-2">
                Starter
              </div>
              <div className="has-text-grey-dark is-size-3 mb-2 has-text-weight-bold">
                € 750
              </div>
            </div>
            <div className="has-text-grey-dark mb-5 is-size-5">
              Eenvoudig & snel je onderneming op het web. Voor starters,
              freelancers en kleine zelfstandigen.
            </div>
            <ul className="has-text-grey-dark is-size-6 mt-3 mb-3">
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                One pager of landingspagina
              </li>
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                Stijlvolle vormgeving
                <br />
                aangepast aan huisstijl
              </li>
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                Basis GDPR
              </li>
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                Basis SEO
              </li>
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                Contact form
              </li>
              <li className="is-flex is-align-items-center">
                <IconExcluded />
                Zelf content beheren
              </li>
              <li className="is-flex is-align-items-center">
                <IconExcluded />
                Webshop
              </li>
            </ul>

            <div className="mt-a has-text-centered">
              {/*<button className="button is-danger ">Kies</button>*/}
              <Link
                to="/contact"
                className="button is-danger has-text-centered mt-3"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
        <div className="column is-4 is-flex">
          <div className="box has-background-white is-flex is-flex-direction-column">
            <div className="has-text-grey-dark is-size-4 font-medium mb-2">
              Groeier
            </div>
            <div className="has-text-grey-dark is-size-3 mb-2 has-text-weight-bold">
              € 1250
            </div>
            <div className="has-text-grey-dark mb-5 is-size-5">
              Eenvoudig & snel je onderneming op het web. Met een content
              management systeem ga je zelf aan de slag.
            </div>
            <ul className="has-text-grey-dark is-size-6 mt-3 mb-3">
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                WordPress CMS = zelf content beheren
              </li>
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                Stijlvolle vormgeving
                <br />
                aangepast aan huisstijl
              </li>
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                Contact pagina + 3 basis pagina's
              </li>
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                Basis GDPR
              </li>
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                Basis SEO
              </li>
              <li className="is-flex is-align-items-center">
                <IconExcluded />
                Webshop
              </li>
            </ul>
            <div className="mt-a has-text-centered">
              {/*<button className="button is-danger ">Kies</button>*/}
              <Link
                to="/contact"
                className="button is-danger has-text-centered mt-3"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
        <div className="column is-4 is-flex">
          <div className="box has-background-white is-flex is-flex-direction-column">
            <div className="has-text-grey-dark is-size-4 font-medium mb-2">
              Maatwerk
            </div>
            <div className="has-text-grey-dark is-size-3 mb-2 has-text-weight-bold">
              Offerte
            </div>
            <div className="has-text-grey-dark mb-5 is-size-5">
              Overleg met ons wat de beste oplossing is voor jouw situatie.
            </div>
            <ul className="has-text-grey-dark is-size-6 mt-3 mb-3">
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                Integratie van API
              </li>
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                Webshop WooCommerce
              </li>
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                Webshop Shopify
              </li>
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                GatsbyJS/NextJs met headless CMS
              </li>
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                Headless CMS: WordPress, Strapi
              </li>
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                ReactJS
              </li>
              <li className="is-flex is-align-items-center">
                <IconIncluded />
                Consultancy
              </li>
            </ul>
            <div className="mt-a has-text-centered">
              {/*<button className="button is-danger ">Kies</button>*/}
              <Link
                to="/contact"
                className="button is-danger has-text-centered mt-3"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="has-text-centered mt-6">
        Vermelde prijzen zijn exclusief BTW
      </div>
    </div>
  )
}

export default PrijsTabel
